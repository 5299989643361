export const PROJECTS = [
  {
    title: "Mice Game",
    description:
      "<p>A mice game where the AI mice need to reach the cheese. Use random objects on the table to guide them.</p>" +
      '<p>You can try the demo <a href="/demo/mice">here</a>.</p>',
    image: "./images/mice.png"
  },
  {
    title: "Village Space Program",
    description:
      "<p>Village Space Program is a click-to-collect type game about taking care of a farm, trading goods for rocket parts and starting your own space program. The main feature is the time and level based collection system.</p>",
    image: "./images/vsp.gif"
  },
  {
    title: "Guitar controlled platformer",
    description:
      "<p>An experiment using an unsual form of input - an electric guitar. The game uses microphone input (routed from a guitar) which is interpreted as different frequencies. Certain frequencies are mapped to a character controller allowing basic movement.</p>" +
      "<p>You can view a video demo <a href='https://youtu.be/jSam-VsWSBM'>here</a>.</p>",
    image: "./images/guitar.jpg"
  },
  {
    title: "Bueno",
    description:
      '<p>A remake of the classical "Uno" game. Features multiple card moves, move validation, multiplayer setting, rudimentary AI.</p>',
    image: "./images/bueno.gif"
  },
  {
    title: "Interactive keyboard button",
    description:
      "<p>Small study of animation states. A clickable keyboard button simulator.</p>" +
      "<p>You can try the demo <a href='/demo/button'>here</a></p>",
    image: "./images/button.gif"
  },
  {
    title: "Mobile ticket app",
    description:
      "<p>A UI creation study. A clone of the interface for a bus ticket application. Features a fully dynamic system with a counter based on ticket purchase and expiration time.<p>",
    image: "./images/tickets.gif"
  },
  {
    title: "AR Building application",
    description:
      "<p>An AR app for creating meshes. My contribution includes the ability to place vertices on floors and walls. These in turn can be used to create new planes, walls, and fences. The mesh textures and height can be adjusted during runtime.<p>" +
      "<p>I worked on this project at <a href='https://zubr.co'>Zubr</a></p>",
    image: "./images/ar.gif"
  },
  {
    title: "AR Core inside-out tracking",
    description:
      "<p>A mobile VR experience exploring the surface of Venus. My contribution includes a system for recalibrating the in-game environment by utilising real world marker images.</p>" +
      "<p>I worked on this project at <a href='https://zubr.co/work/eden-project/'>Zubr</a></p>",
    image: "./images/tracking.png"
  },
  {
    title: "Forest",
    description:
      "<p>A relaxing procedurally generated experience. A concept of getting lost in nature.</p>" +
      "<p>You can view the project on <a href='https://github.com/tadget/Forest/tree/development'>Github</a></p>",
    image: "./images/forest.gif"
  },
  {
    title: "Pogo Painter",
    description:
      "<p>A collaboration with a friend to recreate the classic 'Crash Bash' minigame 'Pogo Painter'. I was responsible for the map generation mechanics and collectable item spawning. Maps are built from abstract definitions which allows for highly customizable configurations. A graph was used to allow further integration of AI agents which would play alongside humans.</p>" +
      "<p>You can try the demo <a href='/demo/pogo'>here<a/></p>",
    image: "./images/pogo.gif"
  }
];
