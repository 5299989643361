export const MODELS = [
  {
    title: "Donut and Coffee",
    image: "../images/models/donuts.png"
  },
  {
    title: "Chairs",
    image: "../images/models/chairs.png"
  },
  {
    title: "Mug",
    image: "../images/models/mug.png"
  },
  {
    title: "Bench",
    image: "../images/models/bench.png"
  }
];
